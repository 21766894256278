export const country_code = [
    {"Andorra": "AD"},
    {"Angola": "AO"},
    {"Antuilla": "AI"},
    {"Antarctica": "AQ"},
    {"Antigua and Barbuda": "AG"},
    {"Argentina": "AR"},
    {"Armenia": "AM"},
    {"Aruba": "AW"},
    {"Australia": "AU"},
    {"Austria": "AT"},
    {"Azerbaijan": "AZ"},
    {"Bahamas": "BS"},
    {"The Bahamas": "BS"},
    {"Bahrain": "BH"},
    {"Bangladesh": "BD"},
    {"Barbados": "BB"},
    {"Belarus": "BY"},
    {"Belgium": "BE"},
    {"Belize": "BZ"},
    {"Bermuda": "BM"},
    {"Bhutan": "BT"},
    {"Bolivia": "BO"},
    {"Bonaire, Sint Eustatius and Saba": "BQ"},
    {"Bosnia and Herzegovina": "BA"},
    {"Botswana": "BW"},
    {"Bouvet Island": "BV"},
    {"Brazil": "BR"},
    {"British Virgin Islands": "VG"},
    {"British Indian Ocean Territory": "IO"},
    {"Brunei": "BN"},
    {"Bulgaria": "BG"},
    {"Burkina Faso": "BF"},
    {"Burundi": "BI"},
    {"Cambodia": "KH"},
    {"Cameroon": "CM"},
    {"Canada": "CA"},
    {"Cabo Verde": "CV"},
    {"Cayman Islands": "KY"},
    {"Central African Republic": "CF"},
    {"Chad": "TD"},
    {"Chile": "CL"},
    {"China": "CN"},
    {"Hong Kong": "HK"},
    {"Macau": "MO"},
    {"Christmas Island": "CX"},
    {"Cocos Islands": "CC"},
    {"Columbia": "CO"},
    {"Comoros": "KM"},
    {"Republic of the Congo": "CG"},
    {"Democratic Republic of the Congo": "CD"},
    {"Cook Islands": "CK"},
    {"Costa Rica": "CR"},
    {"Cote d'Ivoire": "CI"},
    {"Croatia": "HR"},
    {"Cuba": "CU"},
    {"Cyprus": "CY"},
    {"Czech Republic": "CZ"},
    {"Denmark": "DK"},
    {"Djibouti": "DJ"},
    {"Dominica": "DM"},
    {"Dominican Republic": "DO"},
    {"Ecuador": "EC"},
    {"Egypt": "EG"},
    {"El Salvador": "SV"},
    {"Equatorial Guinea": "GQ"},
    {"Eritrea": "ER"},
    {"Estonia": "EE"},
    {"Ethiopia": "ET"},
    {"Eswatini": "SZ"},
    {"Falkland Islands": "FK"}, 
    {"Faroe Islands": "FO"},
    {"The Faroe Islands": "FO"},
    {"Fiji": "FJ"},
    {"Finland": "FI"},
    {"France": "FR"},
    {"French Guiana": "GF"},
    {"French Polynesia": "PF"},
    {"French South Territories": "TF"},
    {"Gabon": "GA"},
    {"Gambia": "GM"},
    {"Georgia": "GE"},
    {"Germany": "DE"},
    {"Ghana": "GH"},
    {"Gibralter": "GI"},
    {"Greece": "GR"},
    {"Greenland": "GL"},
    {"Grenada": "GD"},
    {"Guadeloupe": "GP"},
    {"Guam": "GU"},
    {"Guatemala": "GT"},
    {"Guernsey": "GG"},
    {"Guinea": "GN"},
    {"Guinea-Bissau": "BW"},
    {"Guyana": "Gy"},
    {"Haiti": "HT"},
    {"Heard and Mcdonald Islands": "HM"},
    {"Holy See": "VA"},
    {"Honduras": "HN"},
    {"Hungary": "HU"},
    {"Iceland": "IS"},
    {"India": "IN"},
    {"Indonesia": "ID"},
    {"Iran": "IR"},
    {"Iraq": "IQ"},
    {"Ireland": "IE"},
    {"Isle of Man": "IM"},
    {"Israel": "IL"},
    {"Italy": "IT"},
    {"Jamaica": "JM"},
    {"Japan": "JP"},
    {"Jersey": "JE"},
    {"Jordan": "JO"},
    {"Kazakhstan": "KZ"},
    {"Kenya": "KE"},
    {"Kiribati": "KI"},
    {"North Korea": "KP"},
    {"Kuwait": "KW"},
    {"Kyrgyzstan": "KG"},
    {"Laos": "LA"},
    {"Latvia": "LV"},
    {"Lebanon": "LB"},
    {"Lesotho": "LS"},
    {"Liberia": "LR"},
    {"Libya": "LY"},
    {"Liechtenstein": "LI"},
    {"Lithuania": "LT"},
    {"Luxembourg": "LU"},
    {"North Macedonia": "MK"},
    {"Madagascar": "MG"},
    {"Malawi": "MW"},
    {"Malaysia": "MY"},
    {"Maldives": "MV"},
    {"The Maldives": "MV"},
    {"Mali": "ML"},
    {"Malta": "MT"},
    {"Marshall Islands": "MH"},
    {"Martinique": "MQ"},
    {"Mauritania": "MR"},
    {"Mauritius": "MU"},
    {"Mayotte": "YT"},
    {"Mexico": "MX"},
    {"Federated States of Micronesia": "FM"},
    {"Republic of Moldova": "MD"},
    {"Monaco": "MC"},
    {"Mongolia": "MN"},
    {"Montenegro": "ME"},
    {"Montserrat": "MS"},
    {"Morocco": "MA"},
    {"Mozambique": "MZ"},
    {"Burma": "MM"},
    {"Namibia": "NA"},
    {"Nauru": "NR"},
    {"Nepal": "NP"},
    {"Netherlands": "NL"},
    {"Netherlands Antilles": "AN"},
    {"New Caledonia": "NC"},
    {"New Zealand": "NZ"},
    {"Nicaragua": "NI"},
    {"Niger": "NE"},
    {"Nigeria": "NG"},
    {"Niue": "NU"},
    {"Norfolk Island": "NF"},
    {"Northern Mariana Islands": "MP"},
    {"Norway": "NO"},
    {"Oman": "OM"},
    {"Pakistan": "PK"},
    {"Palau": "PW"},
    {"Palestine": "PS"},
    {"Panama": "PA"},
    {"Papua New Guinea": "PG"},
    {"Paraguay": "PY"},
    {"Peru": "PE"},
    {"Philippines": "PH"},
    {"Pitcairn": "PN"},
    {"Poland": "PL"},
    {"Portugal": "PT"},
    {"Puerto Rico": "PR"},
    {"Qatar": "QA"},
    {"Reunion": "RE"},
    {"Romania": "RO"},
    {"Russia": "RU"},
    {"Russian Federation": "RU"},
    {"Rwanda": "RW"},
    {"Saint-Barthelemy": "BL"},
    {"Saint Helena": "SH"},
    {"Saint Helena}, Ascension}, and Tristan da Cunha": "SH"},
    {"Saint Kitts and Nevis": "KN"},
    {"Saint Lucia": "LC"},
    {"Saint-Martin": "MF"},
    {"Saint Martin": "MF"},
    {"Sint Maarten": "SX"},
    {"Saint Pierre and Miquelon": "PM"},
    {"Saint Vincent and teh Grenadines": "VC"},
    {"Samoa": "WS"},
    {"San Marino": "SM"},
    {"Sao Tome and Principe": "ST"},
    {"Saudi Arabia": "SA"},
    {"Senegal": "SN"},
    {"Serbia": "RS"},
    {"Seychelles": "SC"},
    {"Sierra Leone": "SL"},
    {"Singapore": "SG"},
    {"Slovakia": "SK"},
    {"Slovenia": "SI"},
    {"Solomon Islands": "SB"},
    {"Somolia": "SO"},
    {"South Africa": "ZA"},
    {"South Georgia and the South Sandwich Islands": "GS"},
    {"South Sudan": "SS"},
    {"Spain": "ES"},
    {"Sri Lanka": "LK"},
    {"Sudan": "SD"},
    {"Suriname": "SR"},
    {"Svalbard and Jan Mayen Islands": "SJ"},
    {"Sweden": "SE"},
    {"Switzerland": "CH"},
    {"Syria": "SY"},
    {"Taiwan": "TW"},
    {"Tajikistan": "TJ"},
    {"Tanzania": "TZ"},
    {"Thailand": "TH"},
    {"Timor-Leste": "TL"},
    {"Togo": "TG"},
    {"Tokelau": "TK"},
    {"Tonga": "TO"},
    {"Trinidad and Tobago": "TT"},
    {"Tunisia": "TN"},
    {"Turkey": "TR"},
    {"Turkmenistan": "TM"},
    {"Turks and Caicos Islands": "TC"},
    {"Tuvalu": "TV"},
    {"Uganda": "UG"},
    {"Ukrain": "UA"},
    {"United Arab Emirates": "AE"},
    {"United Kingdom": "GB"},
    {"United States": "US"},
    {"US Minor Outlying Islands": "UM"},
    {"Uruguay": "UY"},
    {"Uzbekistan": "UZ"},
    {"Vanuatu": "VU"},
    {"Venezuela": "VE"},
    {"Vietnam": "VN"},
    {"US Virgin Islands": "VI"},
    {"Wallis and Futuna Islands": "WF"},
    {"Western Sahara": "EH"},
    {"Yemen": "YE"},
    {"Zambia": "ZM"},
    {"Zimbabwe": "ZW"},
]


