export const categories = [
    'Art',
    'Comics',
    'Crafts',
    'Dance',
    'Design',
    'Fashion',
    'Film & Video',
    'Food',
    'Games',
    'Journalism',
    'Music',
    'Photography',
    'Publishing',
    'Technology',
    'Theater',
]